import { HomeCom } from "components";

function Home() {
  return (
    <div>
      <HomeCom />
    </div>
  );
}

export default Home;
