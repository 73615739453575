import { AiOutlineClose } from "react-icons/ai";
import {
  AttributesDiv,
  Header,
  ModalWrapper,
  SingleAttribute,
  SubText,
  TextDiv,
} from "./element";
import { Image } from "react-bootstrap";
import nftgalleryimg from "../../../../assets/images/nftgalleryimg.png";
import attributesimg from "../../../../assets/images/attributesImg.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useState } from "react";
import { CommonUtility } from "utility/common";

function PreviewModal({ data,handleCancel }) {
  const [showAttributes, setShowAttributes] = useState(false);
  console.log("data",data)
  return (
    <ModalWrapper>
      <AiOutlineClose className="close-icon" onClick={handleCancel} />
      <Header>Tapera #{data?.token_id}</Header>
      <SubText>Owned by taperajungle</SubText>
      <Image
        src={CommonUtility.covertIpfsUrl(data?.image)}
        fluid
        width={"486.647px"}
        height={"486.647px"}
      />
      <TextDiv>
        <p className="by-text">
          By <span>8F4CDC</span>
        </p>
        <br /> Tapera Jungle Trees Collection is a collection of 8000 unique 
        trees NFT's - unique digital collectibles living on the Ethereum 
        blockchain. Each NFT is linked to a real tree from the Tapera 
        Jungle, with data of the tree : botanic name, diameter, height, 
        characteristics, and GPS coordinate. Each tree is marked with a 
        metallic plate, with the identification number of the tree. Your 
        Tapera Jungle NFT doubles you as member of Tapera Jungle Club, and 
        grants to members-only, a special access and partnership for events, 
        activities, and all information related to the project, as well as the 
        chance to visit the forest and be actor of this extraordinary Amazonian
         biodiversity conservation project.
      </TextDiv>
      <AttributesDiv onClick={() => setShowAttributes(!showAttributes)}>
        <div className="inner-div">
          <Image src={attributesimg} />
          <p className="attributes-text">Attributes</p>
        </div>
        {showAttributes ? (
          <IoIosArrowUp className="arrow" />
        ) : (
          <IoIosArrowDown className="arrow" />
        )}
      </AttributesDiv>

      {showAttributes && (
        <div className="attributes-wrapper">
          {data?.attributes?.map((x) => (
            <SingleAttribute>
                            <p className="second-text">{x?.trait_type}</p>

              <p className="first-text">

              {x?.value.length > 12 ? `${x?.value.slice(0, 12)}...` : x?.value}
              </p>
            </SingleAttribute>
          ))}
        </div>
      )}
    </ModalWrapper>
  );
}

export default PreviewModal;
