import { NFTGallery } from "components";

function NftGallery() {
  return (
    <div>
      <NFTGallery />
    </div>
  );
}

export default NftGallery;
