import React from "react";
import AdminDashboardCom from "components/adminDashboard";

function AdminDashboard() {
  return (
    <div>
      <AdminDashboardCom />
    </div>
  );
}

export default AdminDashboard;
