import styled from "styled-components";

export const ModalWrapper = styled.div`
  overflow: hidden;
  max-height: 787.414px;
  overflow-y: auto !important;
  background-color: #fffffe;
  border-radius: 5px;
  padding: 2rem 1rem;
  position: relative;

  @media (max-width: 575.98px) {
    max-height: 500px;
  }

  .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #5ea624;
    font-size: 24px;
    cursor: pointer;
  }

  .img-fluid {
    display: block;
    margin: 0 auto;
  }

  .attributes-wrapper {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    @media (max-width: 991.98px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 767.98px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 500.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 320.98px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const Header = styled.h2`
  color: #090a00;
  text-align: center;
  font-size: 20.315px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SubText = styled.p`
  text-align: center;
  color: #033;
  font-size: 14.775px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.315px; /* 137.5% */
`;

export const TextDiv = styled.div`
  max-height: 182.839px;
  overflow-y: auto !important;
  border: 0.923px solid #c2c2c2;
  padding: 1rem;
  margin-top: 2rem;
  color: #033;
  font-size: 14.775px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.315px;

  span {
    cursor: pointer;
    border-bottom: 1px solid #033 !important;
  }

  .by-text {
    color: #033;
    font-size: 14.775px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.315px;
    margin: 0;
  }
`;

export const AttributesDiv = styled.div`
  height: 41.554px;
  border: 0.923px solid #c2c2c2;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer;

  .inner-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .attributes-text {
      color: #033;
      font-size: 15.284px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.016px; /* 137.5% */
      margin: 0;
    }
  }

  .arrow {
    color: #000;
    font-size: 18px !important;
  }
`;

export const SingleAttribute = styled.div`
  width: 100%;
  min-height: 81.262px;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .first-text {
    color: #033;
    font-size: 15.284px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.016px; /* 137.5% */
    margin: 0;
  }
  .second-text {
    color: #033;
    font-size: 15.284px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.016px; /* 137.5% */
    margin: 0;
  }

  .third-text {
    color: #033;
    font-size: 15.284px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.016px; /* 137.5% */
    margin: 0;
  }
`;
