import { Footer, Navbar } from "components";
import {
  ContentWrapper,
  MintBtn,
  NFTGalleryWrapper,
  NftDiv,
  PreviewBtn,
} from "./element";
import {
  Loader,
  MainContainer,
  MainModel,
  openNotification,
} from "components/common";
import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { imgData } from "./data";
import { AiOutlineCheck } from "react-icons/ai";
import { GetNftsImagesHook } from "hooks/nftHooks";
import nftgallerimg from "../../assets/images/nftgalleryimg.png";
import ToastMessage from "components/toast Message/toastMessage";
import { CommonUtility } from "utility/common";
import { useAppDispatch, useAppSelector } from "store/store";
import { btkData, mainModel } from "store/redux/slices/helperSlices/modelSlice";
import { BotanikService } from "web3Functions/botanik";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { userBalanceAsync } from "store/redux/slices/web3ConnectSlice";
import { PreviewDetailsModal } from "components/common/modal";
import { Modal } from "antd";

function NFTGallery() {
  const [showPreviewBtn, setShowPreviewBtn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(false);
  const { web3, accounts } = useAppSelector((state) => state.web3Connect);
  const { botanikData } = useAppSelector((state) => state.model);
  let pageInitial = 20;
  const dispatch = useAppDispatch();

  const [connectModel, setConnectModel] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [page, setPage] = useState(pageInitial);
  const [status, setStatus] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [data, setData] = useState(null);


  const toggleSelection = (index) => {
    if (selectedIndexes.includes(index)) {
      setSelectedIndexes((prevSelectedIndexes) =>
        prevSelectedIndexes.filter((i) => i !== index)
      );
    } else if (selectedIndexes.length < 100) {
      // Changed to 100 from 101
      setSelectedIndexes((prevSelectedIndexes) => [
        ...prevSelectedIndexes,
        index,
      ]);
    } else {
      ToastMessage(
        "Warning",
        "Max 100 NFTs can be selected at a time",
        "warning"
      );
    }
  };

  const { data: nftImages, loading,getNftsImages } = GetNftsImagesHook(page);

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.pageYOffset;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollPosition >= scrollHeight - 180 && !loading) {
      if (page < nftImages?.total) {
        setPage(page + 10);
      }
    }
  };
  useEffect(() => {
    {
      dispatch(btkData());
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [nftImages, page, nftImages?.total, loading]);

  const validateFunc = async () => {
    if (
      botanikData?.isPaused ||
      botanikData?.totalSupply >= botanikData?.phaseLimit
    ) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const mint = async () => {
    try {
      if (status) {
        alert("error");
      } else {
        setMintLoading(true);
        const txn = await BotanikService.mint(
          web3,
          accounts,
          selectedIndexes?.length
        );
        if (txn && txn.status) {
          ToastMessage("Success", "Transaction Successfull", "success");
        }
        if (txn && txn.code) {
          ToastMessage(" ", "Transaction Rejected by User", "error");
          ///////
        }
        dispatch(btkData());
        
        validateFunc();
        setMintLoading(false);
        await getNftsImages(pageInitial)
      }
    } catch (error) {
      setMintLoading(false);
    }
  };

  const connectModelFn = () => {
    setConnectModel(true);

    dispatch(mainModel(true));
  };

  //useEffects
  useEffect(() => {
    web3 && dispatch(userBalanceAsync());
  }, [web3]);

  const handleMouseHover = (index) => {
    setShowPreviewBtn(true);
    setHoveredIndex(index);
  };
  const handleMouseOut = (index) => {
    setShowPreviewBtn(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
console.log("data",data)
  return (
    <NFTGalleryWrapper>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={"1020.261px"}
      >
        <PreviewDetailsModal data={data} handleCancel={handleCancel} />
      </Modal>
      {((loading && page == pageInitial) || mintLoading) && <Loader />}
      {/* { mintLoading) && <Loader />} */}
      <MainModel connectModel={connectModel} />

      <div className="top-content">
        <Navbar />
        <MainContainer>
          <ContentWrapper>
            <NftDiv>
              {nftImages?.nfts_images?.map((item, index) => (
                <div
                  onMouseEnter={() => handleMouseHover(index)}
                  onMouseLeave={() => handleMouseOut(index)}
                  className="image-container"
                  key={index}
                >
                  <Image
                    src={CommonUtility.covertIpfsUrl(item.image)}
                    alt={`NFT ${index}`}
                  />

                  {showPreviewBtn && hoveredIndex === index && (
                    <PreviewBtn onClick={() => { showModal(); setData(item); }}>Preview</PreviewBtn>
                  )}

                  <Checkbox
                    className="checkbox"
                    checked={selectedIndexes.includes(index)}
                    onChange={() => toggleSelection(index)}
                  />
                </div>
              ))}
            </NftDiv>

            {selectedIndexes?.length > 0 && accounts ? (
              <div>
                <MintBtn
                  onClick={(event) => {
                    if (botanikData?.totalSupply === botanikData?.maxSupply) {
                      openNotification(
                        "Supply Completed",
                        "Limit Reached",
                        "warning"
                      );
                    } else if (botanikData?.isPaused) {
                      openNotification("Paused", "Minting paused", "warning");
                    } else {
                      mint();
                    }
                  }}
                >
                  Mint {selectedIndexes?.length} NFTs
                </MintBtn>
              </div>
            ) : (
              !accounts &&
              !loading && (
                <MintBtn onClick={() => connectModelFn()}>
                  Connect Wallet
                </MintBtn>
              )
            )}
          </ContentWrapper>
        </MainContainer>
      </div>
      <Footer />
    </NFTGalleryWrapper>
  );
}

export default NFTGallery;
