export type ThemeType = typeof theme; // This is the type definition for my theme object.

export const theme = {
 
  connectModelbg: "#e5f1ea",
  transferModelBg:"#DE4230",
  connectModelbtnBgColor: "#fff",
  connectModelbtnTextColor: "#000",
  inputBgColor:"transparent",
  inputColor:"#fff",
  
};

// const theme = light; // set the light theme as the default.
export default theme;
