import BlogsCom from "components/blogsComp";

function Blogs() {
  return (
    <div>
      <BlogsCom />
    </div>
  );
}

export default Blogs;
