import { SectionsService } from "services/sectionsServices";
import { CommonHook } from "./commonHook";
import { storage } from "firebase";

export const EditSectionSevenHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const editSectionSeven = async (id, data) => {
    try {
      setLoading(true);
      try {
        const storageRef = storage.ref(`/tapera-jungle/${data?.name}`);
        const uploadTask = storageRef.put(data);

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            console.log(error);
          },
          async () => {
            try {
              const downloadURL = await storageRef.getDownloadURL();
              const result = await SectionsService.editSectionSeven(id, {
                image_path: downloadURL,
              });
              if (result.data) {
                setData(result.data);
                setLoading(false);
              }
            } catch (error) {
              console.error("Error getting download URL:", error);
            }
          }
        );
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
    }
  };

  return {
    editSectionSeven,
    data,
    loading,
    error,
  };
};

export const GetSectionSevenHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();
  const getSectionSeven = async () => {
    try {
      setLoading(true);
      const result = await SectionsService.getSectionSeven();
      if (result.response == "success" && result.data) {
        setData(result.data);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    getSectionSeven,
    data,
    loading,
    error,
  };
};
